let ws: WebSocket;
let revision = 0;

let reconnectTimer: number;
const backoff = [
  // Wait 100ms initially, because we could also be
  // disconnected because of a form submit.
  100,
  150,
  200,
  250,
  300,
  350,
  400,
  450,
  500,
  500,
  605,
  750,
  1000,
  1250,
  1500,
  1750,
  2000,
];
let backoffIdx = 0;
function reconnect() {
  if (ws.readyState !== ws.CLOSED) return;

  reconnectTimer = setTimeout(() => {
    if (backoffIdx === 0) {
      console.log(
        `%c Fresh %c Connection closed. Trying to reconnect...`,
        "background-color: #86efac; color: black",
        "color: inherit",
      );
    }
    backoffIdx++;

    try {
      connect();
      clearTimeout(reconnectTimer);
    } catch (_err) {
      reconnect();
    }
  }, backoff[Math.min(backoffIdx, backoff.length - 1)]);
}

function onOpenWs() {
  backoffIdx = 0;
}

function onCloseWs() {
  disconnect();
  reconnect();
}

function connect() {
  const url = new URL("/_frsh/alive", location.origin.replace("http", "ws"));
  ws = new WebSocket(
    url,
  );

  ws.addEventListener("open", onOpenWs);
  ws.addEventListener("close", onCloseWs);
  ws.addEventListener("message", handleMessage);
  ws.addEventListener("error", handleError);
}

connect();

function disconnect() {
  ws.removeEventListener("open", onOpenWs);
  ws.removeEventListener("close", onCloseWs);
  ws.removeEventListener("message", handleMessage);
  ws.removeEventListener("error", handleError);
  ws.close();
}

function handleMessage(e: MessageEvent) {
  const data = JSON.parse(e.data);
  switch (data.type) {
    case "initial-state": {
      if (revision === 0) {
        console.log(
          `%c Fresh %c Connected to development server.`,
          "background-color: #86efac; color: black",
          "color: inherit",
        );
      }

      if (revision === 0) {
        revision = data.revision;
      } else if (revision < data.revision) {
        disconnect();
        // Needs reload
        location.reload();
      }
    }
  }
}

function handleError(e: Event) {
  // TODO
  // deno-lint-ignore no-explicit-any
  if (e && (e as any).code === "ECONNREFUSED") {
    setTimeout(connect, 1000);
  }
}

addEventListener("message", (ev) => {
  if (ev.origin !== location.origin) return;
  if (typeof ev.data !== "string" || ev.data !== "close-error-overlay") {
    return;
  }

  document.querySelector("#fresh-error-overlay")?.remove();
});

// Disconnect when the tab becomes inactive and re-connect when it
// becomes active again
addEventListener("visibilitychange", () => {
  if (document.hidden) {
    disconnect();
  } else {
    connect();
  }
});

// denoCacheMetadata={"headers":{"x-content-type-options":"nosniff","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","referrer-policy":"strict-origin-when-cross-origin","server-timing":"fetchSource;dur=6","last-modified":"Fri, 22 Mar 2024 21:26:14 GMT","content-type":"application/typescript; charset=utf-8","x-amz-server-side-encryption":"AES256","content-length":"2641","etag":"\"609f4f2c348614ab4ce15de3bf0f0feb\"","access-control-allow-origin":"*","x-amz-cf-pop":"LHR50-P6","cross-origin-opener-policy":"same-origin","age":"18446924","x-frame-options":"DENY","vary":"Accept-Encoding, Origin","cache-control":"public, max-age=31536000, immutable","accept-ranges":"bytes","cross-origin-embedder-policy":"same-origin","date":"Fri, 22 Mar 2024 21:45:16 GMT","cross-origin-resource-policy":"same-origin","strict-transport-security":"max-age=63072000; includeSubDomains; preload","x-amz-replication-status":"COMPLETED","server":"deno/gcp-europe-west2","via":"http/2 edgeproxy-h","x-amz-cf-id":"bz3rChltanW2tW3ExBeeK23drVThGVf13As4z5D6ganlFg3U5pv3hA==","x-amz-version-id":"xDOyJ3fchJxhRIPtCGkFu4a7RrKrGpRj","x-cache":"Hit from cloudfront"},"url":"https://deno.land/x/fresh@1.6.8/src/runtime/entrypoints/client.ts","time":1729590839}